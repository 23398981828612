import logo from "./logo.svg";
import "./App.css";
import * as React from "react";
// import stylex from "stylex";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import {
  FindInPage,
  Article,
  ArticleOutlined,
  ArticleTwoTone,
} from "@mui/icons-material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import { CircularProgress } from "@mui/material";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useState } from "react";

import PolydeltaWebLogo from "./polydelta-web-logo.svg";
import Polydelta from "./polydelta.png";
import DOJLogo from "./doj-logo.png";
import DHSLogo from "./dhs-logo.svg";
import FOIALogo from "./foia-logo.svg";

const axios = require("axios").default;

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://polydelta.ai/" target="_blank">
        Polydelta AI
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const agencyCards = [
  {
    name: "DOJ-OIP",
    desc: "Office of Information Policy",
    logo: DOJLogo,
    link: "https://www.foia.gov/?id=8216158f-8089-431d-b866-dc334e8d4758&type=component#agency-search",
  },
  {
    name: "DOJ-OLA",
    desc: "Office of Legislative Affairs",
    logo: DOJLogo,
    link: "https://www.foia.gov/?id=c88a12ea-64e1-4736-bc5a-52b845e5ac80&type=component#agency-search",
  },
  {
    name: "DHS-OIA",
    desc: "Office of Intelligence & Analysis",
    logo: DHSLogo,
    link: "https://www.foia.gov/?id=f62c90b9-47e5-4422-83ca-ce5de18d4bfb&type=component#agency-search",
  },
  {
    name: "Not sure",
    desc: "I'm not sure the right agency is listed",
    logo: FOIALogo,
    link: "https://www.foia.gov/#agency-search",
  },
];

const docCards = [
  {
    name: "Doc1",
    desc: "2008 | Randall County Jail (Feb. 10-12), Amarillo, TX",
    logo: Article,
    link: "http://www.ice.gov/doclib/foia/dfra-ice-dro/randallcountyjailamarillotx0210122009.pdf",
  },
  {
    name: "Doc2",
    desc: "2008 | Randall County Jail (Feb. 14-15), Amarillo, TX",
    logo: Article,
    link: "http://www.ice.gov/doclib/foia/dfra-ice-dro/randallcountyjailamarillotx0214152008.pdf",
  },
  {
    name: "Doc3",
    desc: "2007 | Randall County Sheriff’s Office, Amarillo, TX",
    logo: Article,
    link: "http://www.ice.gov/doclib/foia/dfra-ice-dro/randallcountysheriffsofficeamarillotx0226272007.pdf",
  },
  {
    name: "Not sure",
    desc: "I'm not sure the right doc is listed - search for agency",
    logo: Article,
    link: "https://www.foia.gov/#agency-search",
  },
];

const theme = createTheme();

function App() {
  const [showChoices, setShowChoices] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [request, setRequest] = useState("");
  const [finalReq, setFinalReq] = useState(request);

  const [queriedAgencies, setQueriedAgencies] = useState();

  const likelyAgencies =
    "From left to right, the following agencies are the most likely to have the documents you're looking for. Choose one to start their FOIA process:";
  const likelyDocs =
    "It looks like what you're looking for may already be in the public domain. Try these docs: ";
  const fileReq = "FILE REQUEST";
  const readDoc = "READ DOC";

  const checkReq = () => {
    return finalReq != null && finalReq.includes("Amarillo");
  };

  const findAgency = () => {
    setFinalReq(request);

    // axios
    //   .get("http://127.0.0.1:8000/predict_agency/" + finalReq)
    //   .then(function (response) {
    //     // handle success
    //     console.log(response);
    //   })
    //   .catch(function (error) {
    //     // handle error
    //     console.log(error);
    //   })
    //   .finally(function () {
    //     // always executed
    //   });

    setShowLoading(true);
    setShowChoices(false);
    setTimeout(() => {
      setShowLoading(false);
      setShowChoices(true);
    }, 2000);
  };

  const getAgencies = () => {
    const cards = checkReq() ? docCards : agencyCards;

    const getMedia = (card) => {
      return checkReq() ? (
        <CardMedia
          component={card.logo}
          sx={{
            flexGrow: 1,
            alignSelf: "center",
            width: "100%",
            minHeight: "200px",
          }}
          alt="article"
        />
      ) : (
        <CardMedia
          component="img"
          sx={{
            backgroundColor: "black",
            flexGrow: 1,
          }}
          src={card.logo}
          alt="agency logo"
        />
      );
    };

    return cards.map((card) => (
      <Grid item key={card.name} xs={12} sm={6} md={3}>
        <Card
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {getMedia(card)}
          <CardContent sx={{}}>
            <Typography gutterBottom variant="h5" component="h2">
              {card.name}
            </Typography>
            <Typography>{card.desc}</Typography>
          </CardContent>
          <CardActions>
            <Link href={card.link} target="_blank">
              <Button size="small">{checkReq() ? readDoc : fileReq}</Button>
            </Link>
          </CardActions>
        </Card>
      </Grid>
    ));
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="relative" sx={{ backgroundColor: "black" }}>
        <Toolbar>
          <Box style={styles.headerLogo}>
            <img src={PolydeltaWebLogo} />
          </Box>
          <Typography
            variant="h6"
            color="inherit"
            noWrap
            sx={{ mx: "10px", color: "gray" }}
          >
            |
          </Typography>
          <Typography variant="h6" color="inherit" noWrap>
            FOIA Wizard
          </Typography>
        </Toolbar>
      </AppBar>
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: "background.paper",
            pt: 8,
          }}
        >
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
              FOIA WIZARD
            </Typography>
            <Typography
              variant="h5"
              align="center"
              color="text.secondary"
              paragraph
            >
              Let the Wizard find which agency you should submit your request
              to:
            </Typography>
            <TextField
              sx={{
                width: "100%",
              }}
              id="outlined-basic"
              label="Type or paste request here..."
              variant="outlined"
              multiline={true}
              minRows={6}
              maxRows={15}
              onChange={(e) => {
                setRequest(e.target.value);
              }}
            />
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              <Button
                variant="contained"
                onClick={() => {
                  findAgency();
                }}
              >
                Find Agency
              </Button>
              {showLoading && <CircularProgress />}
            </Stack>
          </Container>
        </Box>
        <Container sx={{ py: 8 }} maxWidth="md">
          {/* End hero unit */}
          {showChoices && (
            <Box style={styles.optionsMessageBox}>
              <Typography style={styles.optionsMessage}>
                {checkReq() ? likelyDocs : likelyAgencies}
              </Typography>
            </Box>
          )}
          <Grid container spacing={4}>
            {showChoices && getAgencies()}
          </Grid>
        </Container>
      </main>
      {/* Footer */}
      <Box style={styles.footer} component="footer">
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
          Powered by Polydelta Discovery Studio
        </Typography>
        <Copyright />
      </Box>
      {/* End footer */}
    </ThemeProvider>
  );
}

const styles = {
  footer: {
    display: "flex",
    bgcolor: "background.paper",
    flexDirection: "column",
  },
  headerLogo: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "black",
    alignSelf: "center",
  },
  optionsMessageBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: 20,
  },
  optionsMessage: {
    textAlign: "center",
    width: "50%",
    color: "text.secondary",
  },
};

export default App;
